body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

input, textarea {
  width: 100%; }

.main-overlay {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.layout-wrapper {
  width: 100%; }

.page-wrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  @include respond-to(table) {
    display: block {
      flex-direction: column; } }
  & > div {
    //margin-left: 20rem
    @include respond-to(table) {
      margin: 0; }
    &:first-child {
      margin-left: 0; } } }


// Bootstrap redefine

.container {
  width: 95%;
  margin: 0 auto;
  padding: 0; }

.btn {
  font-size: $font-size-increased;
  padding: 5rem 10rem;
  border-radius: 4rem; }

.form-group {
  margin-bottom: $font-size; }

.form-control {
  font-size: $font-size;
  height: 35rem;
  padding: 5.25rem 10.5rem;
  border-radius: 3.5rem; }


// Datepicker redefine

div.react-datepicker {
  font-size: 11.2rem;
  border-radius: 4.2rem;
  & .react-datepicker {
    &__navigation {
      line-height: 23.8rem;
      border-width: 6.3rem;
      outline: none; }
    &__header {
      border-top-left-radius: 4.2rem;
      border-top-right-radius: 4.2rem; }
    &__month {
      margin: 5.6rem; }
    &__current-month {
      font-size: 13.216rem; }
    &__day, &__day-name {
      width: 23.8rem;
      line-height: 23.8rem;
      margin: 2.324rem; }
    &__day--selected, &__day--in-range {
      background-color: $bg-header-light; } } }

.single-news-wrapper {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  background-color: #c4c4c4;
  margin: 0 20rem 40rem 0;
  cursor: pointer; }
