.message-modal-wrapper {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4); }
.message-modal {
  background-color: #fefefe;
  margin: auto;
  //padding: 20px
  border: 1px solid #888;
  border-radius: 5rem;
  width: 30%;
  min-height: 250rem;
  position: relative;
  @include respond-to(table) {
    background-color: #fefefe;
    margin: auto;
    //padding: 20px
    border: 1px solid #888;
    border-radius: 5rem;
    width: 90%;
    height: 250rem;
    position: relative; }

  &--header {
    position: absolute;
    height: 50rem;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20rem; }
  &--title {
    font-size: $font-size-large;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    padding: 30rem 0; }
  &--body {
    width: 90%;
    margin: 80rem auto; }
  &--text {
    font-size: $font-size-medium;
    color: $color-font; }
  &--footer {
    position: absolute;
    height: 60rem;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
 }    //margin-bottom: 10rem
  &--btn {
    margin-right: 20rem;
    width: 30%;
    height: 40rem;
    border: none; } }
.active {
  display: block; }

.change-password-modal {
  background-color: #fefefe;
  margin: auto;
  //padding: 20px
  border: 1px solid #888;
  border-radius: 5rem;
  width: 30%;
  //height: 250rem
  position: relative; }

.change-password-body {
  width: 90%;
  margin: 80rem auto 100rem; }
