
// Bootstrap redefine
@mixin respond-to($media) {
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 85%; } }
  @media screen and (max-width: 1200px) {
    .container {
      max-width: 95%; } }

  @media screen and (max-width: 992px) {
    .container {
      max-width: 95%; } }

  @if $media == table {
    @media screen and (max-width: 830px) {
      @content;
      .container {
        max-width: 95%; } } }

  @media screen and (max-width: 768px) {
    .container {
      max-width: 95%; } }

  @if $media == phone {
    @media screen and (max-width: 576px) {
      @content;
      .container {
        max-width: 95%; } } } }


