.about-block {
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-top: 52rem;
    margin-bottom: 24rem;
    @include respond-to(table) {
      margin: 54rem 24rem 20rem; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    //+respond-to(table)
    //  font-size: $font-size-medium
    //  color: $color-font-light
    //  font-style: normal
    //  font-weight: normal
    //  margin: 0 24rem
    //+respond-to(phone)
    //  font-size: $font-size-medium
    //  color: $color-font-light
    //  font-style: normal
    //  font-weight: normal
 }    //  margin: 0 24rem
  &__mobile-text {
    font-size: $font-size-medium;
    color: $color-font-light;
    font-style: normal;
    font-weight: normal;
    @include respond-to(table) {
      font-size: $font-size-medium;
      color: $color-font-light;
      font-style: normal;
      font-weight: normal;
      margin: 0 24rem; }
    @include respond-to(phone) {
      font-size: $font-size-medium;
      color: $color-font-light;
      font-style: normal;
      font-weight: normal;
      margin: 0 24rem; } }
  &__organizers-block {
    display: flex;
    justify-content: space-between;
    margin-top: 36rem;
    margin-bottom: 55rem;
    @include respond-to(table) {
      display: flex;
      flex-direction: column; } }
  &__card {
    width: 30%;
    height: 340rem;
    background: #EEF4F7;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center; }
  &__link {
    text-decoration: none;
    color: $color-font;
    height: 100%;
    @include respond-to(table) {
      width: 100%;
      height: auto;
      margin: 5rem 0;
      border-radius: 0; } }
  &__inner-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 15rem; }
  &__inner-card-image {
    width: 220rem;
    height: auto;
    margin-bottom: 15rem; }
  &__inner-card-title {
    text-align: center;
    font-size: $font-size-large;
    padding: 0 20rem; }
  &__icon {
    width: 123rem;
    height: 100rem;
    color: #A1CADC; }
  &__faq-block-title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $color-font; } }
