.analytical-review-block {
  &__wrapper {
    margin-bottom: 50rem; }
  &__search-block {
    min-width: 100%;
    height: 170rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 75rem;
    @include respond-to(table) {
      height: auto; } }
  &__search-wrapper {
    display: flex;
    justify-content: space-between; }
  &__search-btn-wrapper {
    display: flex;
    justify-content: space-between; }
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    @include respond-to(table) {
      display: flex;
      flex-direction: column;
      //margin: 54rem 24rem 25rem
      margin-bottom: 15rem; } }
  &__single-item {
    margin-bottom: 40rem; }
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    line-height: 33rem;
    margin-bottom: 30rem;
    text-transform: uppercase;
    @include respond-to(table) {
      margin: 0 20rem 10rem; } }
  &__date {
    padding: 0;
    margin: 0;
    font-size: $font-size;
    color: $color-font-extra-light;
    line-height: 1.5;
    @include respond-to(table) {
      margin-left: 20rem; } }
  &__content {
    display: flex;
    //justify-content: space-between
    @include respond-to(table) {
      display: flex;
      flex-direction: column; } }
  &__image-wrapper {
    min-width: 23%;
    height: 160rem;
    //float: left
    display: inline-block;
    text-align: center;
    margin-right: 20px;
    overflow: hidden;
    @include respond-to(table) {
      margin-bottom: 15rem; }
    & > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain; } }
  //&__image
  //  width:
  //  background-color: #c4c4c4
  //  //position: absolute
  //  width: 100%
  //  height: 250rem
  //  //left: 163px
  //  //top: 492.43px
  //  border-radius: 3px
  //  //float: left
  //  margin-right: 20rem
  //  +respond-to(table)
  //    display: block
  //    width: 90%
  //    ma
  //    //height: 320rem
  //    border-radius: 0
  //    margin: 0 auto 25rem
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  &__text-block {
    font-size: $font-size-medium;
    color: $color-font-light;
    //margin-bottom: 25rem
    text-align: justify;
    height: 105rem;
    overflow-y: hidden;
    @include respond-to(table) {
      margin: 0 20rem 20rem 20rem;
      width: 90%;
      height: auto;
      overflow-y: visible; } }

  &__download-link {
    font-size: 20rem;
    text-decoration: underline;
    @include respond-to(table) {
      margin-left: 20rem; } } }


.search-btn {
  width: 150rem;
  margin-left: 30rem; }
