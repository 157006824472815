.footer {
  width: 100%;
  color: $color-font-menu;
  background: $bg-header-medium;
  margin-top: 45rem;
  padding: 50rem 0 80rem;
  @include respond-to(table) {
    padding: 20rem 0 40rem; }
  & .container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }
  &__column {
    width: 24.5%;
    @include respond-to(table) {
      width: 100%;
      margin-left: 25rem; }
    &--to-bottom {
      text-align: right;
      align-self: flex-end; } }
  &__with-items:only-child {
    @include respond-to(table) {
      margin: 5rem 0; } }
  &__title {
    font-size: $font-size-medium;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15rem; }
  &__link {
    text-decoration: none;
    color: $color-font-menu;
    font-size: $font-size-increased;
    line-height: 19rem;
    margin-bottom: 10rem;
    &:hover {
      text-decoration: none;
      color: $color-font-menu-active; } }
  &__text p {
    font-size: $font-size-increased;
    line-height: 19rem;
    margin-bottom: 10rem; }
  &__socials-item {
    font-size: $font-size-h3;
    color: $color-font-menu;
    margin-right: 25rem;
    display: inline-block;
    padding: 0;
    width: 25rem;
    height: 25rem;
    background-size: cover; }
  &__socials-img {
    width: 100%;
    height: 100%;
    &:last-child {
      margin-right: 0; }
    &:hover {
      color: $color-font-menu-active; } }
  &__designed-block {
    float: right;
    text-align: center;
    font-size: $font-size-decreased;
    & > p {
      opacity: .8; } }
  &__logo-text {
    //text-decoration: none
    color: $color-font-menu; }
  //&__logo-text:hover
  //  text-underline: none
  &__logo-yorc {
    width: 70rem;
    height: auto; }
  &__yorc-link {
    text-decoration: none; }
  &__yorc-link:hover {
    text-decoration: none; } }



.information {
  margin-left: 90rem; }
