.breadcrumbs__wrapper {
  margin-bottom: 20rem;
  margin-left: 0; }

.breadcrumb {
  list-style: none;
  background: #fff;
  font-size: 18rem; }

.breadcrumb-item:first-child {
  margin-left: 0;
  padding-left: 0; }

.breadcrumb-item {
  margin: 0 5rem;
  text-decoration: none;
  padding-left: 5rem; }

.breadcrumb-item:hover {
  text-decoration: none; }


.separator {
  margin-right: 5rem;
  color: #545454;
  align-items: center; }

.breadcrumbs {
  &__wrapper {
    margin-left: 0;
    //width: 85%
 } }    //margin: 0 auto 30rem

.main-layout-breadcrumbs {
  width: 85%;
  margin: 0 auto 30rem; }
