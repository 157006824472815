.header {
  width: 100%;
  color: $color-font-menu;
  margin-bottom: 45rem;
  position: relative;
  @include respond-to(table) {
    margin-bottom: 0; }
  & .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    z-index: 100; }
  & .profile-btn-wrapper {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 130rem; }
  & .profile-btn-wrapper:hover {
      //.profile-btn__dropdown-menu > ul
      ////animation: profile-dropdown-open 300ms ease-in-out forwards
      .profile-dropdown-open {
        animation: profile-dropdown-open 150ms ease-in-out forwards; } }
  & .profile-btn {
    color: $color-font-menu;
    font-size: $font-size-large;
    padding: 0;
    margin-top: 5rem;
    //margin-left: 25rem
    @include respond-to(table) {
      margin: 0 20rem 0; }
    &:hover, &:active, &:focus {
      color: $color-font-menu-active;
      text-decoration: none; }
    &__avatar {
      height: 50rem;
      width: 50rem;
      border-radius: 50%;
      @include respond-to(table) {
        width: 30rem;
        height: 30rem; } }
    &__dropdown-menu {
      overflow: hidden; }
    &__dropdown-menu > ul {
      display: inline;
      overflow: hidden;
      position: absolute;
      top: 112rem;
      left: -38rem;
      background-color: #3EAACC;
      padding-left: 40rem;
      border-radius: 0 0 3rem 3rem;
      list-style: none;
      width: 193rem; }
    &__dropdown-menu > ul {
      height: 0; }
    &__dropdown-menu > ul > li > span {
      font-size: $font-size-medium;
      margin-bottom: 15rem;
      display: block;
      color: $color-font-menu;
      text-decoration: none;
      margin-top: 15rem;
      cursor: pointer; }
    &__dropdown-menu > ul > li > span:hover {
      color: $color-font-menu-active; }
    &__initial {
      height: 0;
      .profile-dropdown-close {
        animation-delay: 1s;
        animation: profile-dropdown-close 150ms ease-in-out forwards; } } }
  &__mobile-avatar-username-wrapper {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 10rem; }

  &__mobile-header-top {
    width: 100%;
    height: 70rem;
    background-color: $bg-header-medium;
    display: flex;
    justify-content: space-between; }
  &__menu-icon {
    font-size: 24rem;
    margin-top: 24rem;
    margin-right: 25rem;
    margin-left: 15rem; }
  &__mobile-promo {
    width: 100%;
    min-height: 250rem;
    height: auto;
    background-color: $bg-extra-light;
    margin-bottom: 25rem; }
  &__mobile-promo-wrapper {
    padding: 20rem;
    margin-bottom: 20rem; }
  &__mobile-promo-wrapper > h3 {
    font-size: $font-size-h4;
    font-weight: bold;
    color: $color-font; }
  &__mobile-promo-wrapper > p {
    font-size: $font-size-increased;
    color: $color-font-light;
    //text-align: justify
    line-height: 30rem; }
  &__mobile-menu-block {
    padding: 10rem 0 20rem 0;
    background-color: $bg-header-light; }
  &__mobile-menu {
    list-style: none; }
  &__mobile-sub-menu {
    list-style: none;
    padding-left: 0; }
  &__mobile-sub-menu-wrapper {
    background-color: $bg-header-medium;
    margin-left: -40rem;
    padding-left: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
  &__mobile-menu-title {
    font-size: $font-size-medium;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 18rem; }
  &__mobile-menu-sub-title {
    font-size: $font-size-medium;
    text-transform: initial;
    font-weight: 500;
    margin: 13rem 0;
    line-height: 26rem; }
  .has-investments {
    cursor: pointer; }
  .mobile-menu-hidden {
    overflow: hidden;
    height: 0;
    padding: 0;
 }    //padding: 10rem 0 20rem 0
  .mobile-menu-close {
    animation: mobile-menu-close 150ms ease forwards; }
  .mobile-menu-visible {
    height: 100%;
    padding: 10rem 0 20rem 0;
    animation: mobile-menu-open 150ms ease forwards; }
  .mobile-sub-menu-hidden {
    overflow: hidden;
    height: 0; }
  .mobile-sub-menu-close {
    animation: mobile-sub-menu-close 150ms ease forwards; }
  .mobile-sub-menu-visible {
    visibility: visible;
    height: 80rem;
    animation: mobile-sub-menu-open 150ms ease forwards; }
  &__mobile-search {
    display: flex;
    font-size: $font-size;
    font-style: italic; }
  &__mobile-profile-menu-title {
    margin: 20rem 0;
    cursor: pointer; }
  .mobile-profile-menu-hidden {
    overflow: hidden;
    height: 0;
    padding: 0; }
  .mobile-profile-menu-close {
    animation: mobile-profile-menu-close 150ms ease forwards; }
  .mobile-profile-menu-visible {
    height: 160rem;
    padding: 10rem 0 20rem 0;
    animation-delay: 200ms;
    animation: mobile-profile-menu-open 150ms ease forwards; }


  &__search-input {
    outline: none; }

  &__search-input-mobile {
    background-color: $bg-header-dark;
    border: none;
    width: 60rem;
    outline: none;
    color: $color-font-menu; }
  &__search-input-mobile::placeholder {
    color: $color-font-menu;
    font-style: italic; }

  &__mobile-search-icon {
    margin-left: 5rem;
    margin-right: 35rem; }

  &__mobile-menu-login {
    font-size: $font-size-medium;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20rem;
    margin-left: 40rem; }

  & .header__top {
    height: 60rem;
    background: $bg-header-medium;
    @include respond-to(table) {
      background: $bg-header-dark;
      height: 30rem; } }
  & .header__nav {
    height: 95rem;
    background: $bg-header-light; }
  & .header__promo {
    height: 240rem;
    color: $color-font-light;
    background-color: #fff;
    background-position: right;
    background-size: auto 100%;
    & .container {
      justify-content: flex-start; }
    &__border {
      width: 4px;
      margin: 0 50rem;
      padding: 75rem 0;
      background: $bg-header-medium; }
    &__description {
      width: 50%;
      max-width: 600rem;
      font-size: 16rem;
      & h3 {
        font-size: 24rem;
        font-weight: bold;
        line-height: 28rem;
        color: $color-font;
        margin-bottom: 10rem; } } }
  &__mobile-profile-wrapper {
    display: flex;
    justify-content: space-around; }
  &__username {
    font-size: 10rem;
    margin-bottom: -5rem;
    text-align: center;
    margin-bottom: 10rem; } }


.lang-control {
  @include respond-to(table) {
    margin-left: 8rem; }
  &__item {
    color: $color-font-menu;
    font-size: $font-size;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 15rem;
    padding: 0;
    @include respond-to(table) {
      font-size: $font-size;
      margin-right: 25rem;
      margin-top: 2px; }
    &:last-child {
      margin-right: 0; }
    &:hover {
      color: $color-font-menu-active;
      text-decoration: none; }
    &:active, &:focus {
      color: $color-font-menu;
      text-decoration: none; }
    &--active {
      color: $color-font-menu-active;
      &:hover, &:active, &:focus {
        color: $color-font-menu-active; } } } }

.search-wrapper {
  position: relative;
  & input {
    width: 304rem;
    height: 36rem;
    background: $color-font-menu;
    font-style: italic;
    padding: 10rem;
    border: none;
    border-radius: 2px;
    &::placeholder {
      color: rgba(16, 135, 173, 0.7); } }
  & .fa {
    position: absolute;
    top: 10rem;
    right: 10rem;
    color: rgba(16, 135, 173, 0.7);
    cursor: pointer; } }

.main-nav {
  z-index: 1000;
  &__item {
    color: $color-font-menu;
    font-size: $font-size-medium;
    text-decoration: none;
    padding: 0;
    margin: 12rem 0;
    display: block;
    &:last-child {
      margin-right: 0; }
    &:active, &:focus {
      color: $color-font-menu;
      text-decoration: none; }
    &:hover {
      color: $color-font-menu-active;
      text-decoration: none; }
    &--hover {
      color: $color-font-menu-active !important; }
    &--active {
      font-weight: bold; } }
  &__dropdown-group {
    position: relative;
    display: inline-block;
    margin-right: 25rem; }
  &__dropdown {
    display: none;
    position: absolute;
    min-width: 360rem;
    top: 49rem;
    left: -10rem;
    background: $bg-header-light;
    padding: 25rem 25rem 0;
    &--opened {
      display: block; } }
  &__register-login-wrapper {
    display: flex; } }
.register {
  margin-right: 15rem; }
.register, .login {
  cursor: pointer; }

.logo-header-menu {
  width: 220rem;
  height: 55rem;
  cursor: pointer; }

.always-menu {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100000; }

.username-desktop {
  text-align: center;
  line-height: 18rem;
  margin-bottom: 5rem; }
