$color-font: #393939;
$color-font-light: #666666;
$color-font-extra-light: #a2a2a2;
$color-font-menu: #ffffff;
$color-font-menu-active: #87e2ff;
//$color-font-date: #A2A2A2

$bg-header-light: #34a2c5;
$bg-header-medium: #1087ad;
$bg-header-dark: #0d7191;
$bg-extra-light: #eaf2f9;
//$bg-extra-light: #f1f8fb
$bg-light: #e1f2ff;
$bg-medium: #d0e3f0;
$bg-logo-gradient: linear-gradient(253.27deg, #1087ad -0.99%, #56bcdc 100%);
$bg-rubric-gradient: linear-gradient(209.62deg, rgba(16, 135, 173, 0.75) 0%, rgba(80, 183, 216, 0.75) 100%);
$bg-rubric-hover-gradient: linear-gradient(209.62deg, #1087ad 0%, #50b7d8 100%);

$font-size-decreased: 12rem;
$font-size: 14rem;
$font-size-increased: 16rem;
$font-size-medium: 18rem;
$font-size-large: 22rem;

$font-size-h3: 28rem;

$border-color: #e4eaee;
$border-headline-color: #d86363;
//$font-size-super-large: 28rem

$font-size-h4: 24rem;
