.document-modal-wrapper {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: calc(100% + 40rem);
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0 -20rem;
  @include respond-to(table) {
    margin: 0 -15rem; } }

.document-modal {
  background-color: #fefefe;
  margin: 0 auto 100rem;
  //padding: 20px
  border: 1px solid #888;
  border-radius: 5rem;
  width: 40%;
  //height: 380rem
  position: relative;
  @include respond-to(table) {
    width: 85%; }
  &--inner-wrapper {
    width: 80%;
    margin: 0 auto; }
  &--header {
    position: absolute;
    height: 60rem;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start; }
  &--close-icon {
    position: absolute;
    right: 15rem;
    top: 15rem;
    font-size: 22rem; }
  &--title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    padding: 30rem 0;
    margin-left: 40rem; }
  &--body {
    width: 80%;
    margin: 100rem auto; }
  &--form-group {
    margin-bottom: 20rem; }
  &--cover-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include respond-to(table) {
      display: flex;
      flex-direction: column;
      justify-content: space-around; } }
  &--cover-upload {
    width: 60%;
    height: 180rem;
    background: #C4DAE7;
    margin: 0 0 15rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to(table) {
      width: 100%; } }
  &--upload-icon {
    font-size: 120rem;
    color: rgba(140, 140, 140, 0.5); }
  &--cover-upload-btn {
    width: 60%;
    height: 35rem;
    background: $bg-header-light;
    text-align: center;
    line-height: 35rem;
    color: #fff;
    font-size: 18rem;
    border-radius: 7rem;
    @include respond-to(table) {
      width: 100%; } }
  &--document-upload-btn {
    width: 60%;
    height: 35rem;
    background: $bg-header-light;
    text-align: center;
    line-height: 35rem;
    color: #fff;
    font-size: 18rem;
    border-radius: 4rem;
    @include respond-to(table) {
      width: 100%; } }

  &--cover-upload-input {
    display: none; }
  &--image {
    width: 60%;
    height: 180rem;
    margin: 0 0 15rem;
    border-radius: 5rem; }
  &--textarea {
    height: 100rem !important; }
  &--datepicker-wrapper {
    width: 100%; }
  &--datepicker {
    height: 35rem;
    border: 1px solid $bg-header-light;
    border-radius: 3px;
    outline: none;
    padding-left: 15rem; }

  &--footer {
    position: absolute;
    height: 60rem;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10rem; }
  &--btn {
    width: 80%;
    height: 40rem;
    border: none; } }
.active {
  display: block; }
.document-icon {
  margin-top: 15rem; }
.document-name {
  margin-left: 20rem; }
.document-error {
  margin-top: 20rem; }
