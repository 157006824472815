.documents-form-block {
  &__documents-form-main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__search-block {
    width: 100%;
    height: 240rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 75rem;
    @include respond-to(table) {
      height: auto; }
    &--search-title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      margin-bottom: 20rem; } }
  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      flex-direction: column;
      align-items: center; } }
  &__form-group, .form-group {
    display: flex;
    flex-direction: column;
    width: 23%;
    @include respond-to(table) {
      width: 100%; } }
  &__input-label, .form-label {
    margin-bottom: 10rem; }
  &__first-row {
    margin-bottom: 25rem; }
  &__search-btn {
    width: 18%;
    @include respond-to(table) {
      width: 100%; } }
  &__redirect {
    cursor: pointer;
    color: $bg-header-light; }
  &__redirect:hover {
    color: $bg-header-medium; } }

.single-document-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin-top: 20rem;
      margin-bottom: 25rem; } }
  &__img {
    width: 445rem;
    height: auto;
    display: inline;
    @include respond-to(table) {
      width: 100%;
      margin-bottom: 25rem; } }
  &__full-name {
    font-size: 20rem;
    font-weight: bold;
    margin-bottom: 10rem; }
  &__row-wrapper {
    margin-bottom: 10rem; }
  &__info-title {
    font-size: $font-size-medium;
    color: $color-font-light; }
  &__user-info-title {
    font-size: $font-size-medium;
    color: $color-font-light;
    font-weight: bold; }
  &__info-description {
    font-size: $font-size-medium;
    font-weight: bold;
    color: $color-font; } }

