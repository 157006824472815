.forum-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 20rem; }
  &__search-block {
    width: 100%;
    height: 240rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 55rem;
    border-radius: 3px;
    @include respond-to(table) {
      height: auto; } }
  &__search-title {
    font-size: $font-size-large;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 20rem; }
  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      flex-direction: column;
      align-items: center; } }
  &__input-label {
    margin-bottom: 10rem; }
  &__form-group {
    width: 22%;
    @include respond-to(table) {
      width: 100%; } }
  &__search-btn {
    width: 22%;
    @include respond-to(table) {
      width: 100%; } }
  &__text-input {
    width: 50%;
    @include respond-to(table) {
     width: 100%; } }
  &__first-row {
    margin-bottom: 25rem; }
  &__questions-block {
    &--title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $color-font-light;
      margin-bottom: 7rem;
      cursor: pointer; }
    &--text {
      font-size: $font-size-medium;
      color: $color-font-light;
      margin-bottom: 45rem;
      text-align: justify;
      height: 50rem;
      overflow-y: hidden; }
    &--mobile-text {
      font-size: $font-size-medium;
      color: $color-font-light;
      margin-bottom: 45rem;
      height: 50rem;
      overflow-y: hidden; } } }

.question-on-forum-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 18rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__question-title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $color-font-light;
    margin-bottom: 7rem; }
  &__question-answer {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    margin-bottom: 45rem; }
  &__mobile-question-answer {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 45rem; } }

.button-row {
  display: flex;
  justify-content: flex-start; }

.forum-btn {
  width: 150rem;
  &__search {
    margin-right: 20px; } }
