.practice-organization-block {
  &__wrapper {
    margin-bottom: 80rem; }
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    line-height: 33rem;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 54rem 24rem 24rem; } }
  &__text-block {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 25rem;
    text-align: justify;
    @include respond-to(table) {
      margin: 0 24rem 10rem; } }
  &__link-block {
    @include respond-to(table) {
      margin: 0 24rem; } }
  &__link {
    font-size: $font-size-large;
    color: $bg-header-medium;
    text-decoration: underline;
    font-weight: bold;
    margin-right: 60rem;
    cursor: pointer;
    @include respond-to(table) {
      margin-right: 30rem;
      line-height: 40rem; } } }
