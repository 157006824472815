.events-block {
  background-color: #EAF2F9;
  width: 100%;
  height: 550rem;
  margin-bottom: 50rem;
  &__head-wrapper {
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
 }    //align-items: stretch
  &__head-title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $bg-header-medium;
    //padding-top: 50rem
    margin-bottom: 25rem;
    text-transform: uppercase; }
  &__input-label {
    font-size: $font-size-increased;
    margin-bottom: 10rem;
    width: 100%; }
  &__input {
    width: 95%;
    height: 35rem;
    background: #FBFBFB;
    border: 1px solid #34A2C5;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 15rem;
    font-size: $font-size-increased;
    margin-bottom: 25rem;
    outline: none; }
  &__input:focus {
    border: none; }
  &__input::placeholder {
    color: #D0C9D6; }
  &__gender-btn-wrapper {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25rem; }
  &__gender-btn {
    width: 203rem;
    height: 35rem;
    border: none;
    background-color: #81A0A9;
    border-radius: 4rem;
    font-size: $font-size-medium;
    color: $color-font-menu;
    font-weight: bold;
    outline: none; }
  &__btn-icon {
    height: 16rem;
    margin-right: 10rem;
    line-height: 1; }
  &__gender-btn-active {
    background-color: #34A2C5; }
  //&__select-wrapper
  //  margin-bottom: 10rem
  &__register-btn {
    width: 95%;
    background-color: #1DBA78;
    border: none;
    height: 35rem;
    color: $color-font-menu;
    font-size: $font-size-medium;
    font-weight: bold;
    border-radius: 4rem;
    outline: none; }
  &__date-picker-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15rem;
    @include respond-to(table) {
      display: flex;
      justify-content: space-between; } }
  &__date-picker-input-inner-wrapper {
    @include respond-to(table) {
      width: 45%;
      margin: 0 20rem 10rem; } }
  //  position: relative
  //&__date-picker-inner-content
  //  position: absolute
  //  top: 1rem
  //  left: 1rem
  //  background-color: #E5F0F7
  //  padding: 6rem 9rem
  //  border-radius: 3px 0 0 3px
  &__date-picker-input {
    width: 125rem;
    outline: none;
    height: 35rem;
    background: #FBFBFB;
    border: 1px solid #34A2C5;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 15rem;
    font-size: $font-size-increased;
    @include respond-to(table) {
      width: 100%; } }
  &__date-picker-wrapper {
    margin-bottom: 20rem;
    //width: 320rem
    //height: 320rem
    //background-color: #C4C4C4
    //border-radius: 6rem
    //text-align: center
    //padding-top: 45rem
 }    //transform: scale(1.5)
  &__events {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -16rem;
    @include respond-to(table) {
      margin: 0;
      display: flex;
      flex-direction: column; } }
  &__single-event {
    width: 22%;
    margin: 16rem;
    @include respond-to(table) {
      width: 100%;
      margin: 20rem 0 20rem; } }
  &__image-wrapper {
    width: 100%;
    height: 190rem;
    border-radius: 3px;
    margin-bottom: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer; }
  &__image-wrapper:hover {
    background-color: #f6f6f6;
    border: 1px solid #f0f0f0;
    transform: scale(1.1); }
  &__image {
    border-radius: 3px;

    @include respond-to(table) {
      border-radius: 0;
      height: 220rem; } }
  &__event-title {
    font-size: $font-size-h4;
    color: $bg-header-light;
    cursor: pointer;
    margin-bottom: 5rem;
    text-align: center;
    @include respond-to(table) {
      margin: 0 20rem; }
    &__preloader-wrapper {
      width: 100%;
      margin: 50rem auto; } } }

.event-date-picker-wrapper div.react-datepicker {
  font-size: 14.28rem;
  border-radius: 5.355rem;
  & .react-datepicker {
    &__navigation {
      line-height: 30.345rem;
      border-width: 8.0325rem;
      outline: none; }
    &__header {
      border-top-left-radius: 5.355rem;
      border-top-right-radius: 5.355rem; }
    &__month {
      margin: 8.4rem; }
    &__current-month {
      font-size: 16.8504rem; }
    &__day, &__day-name {
      width: 30.345rem;
      line-height: 30.345rem;
      margin: 3.486rem; } } }
.event-date-picker-wrapper {
  @include respond-to(table) {
    display: flex;
    justify-content: space-around; } }

.date-picker-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  @include respond-to(table) {
    margin: 40rem 20rem 5rem; } }

.events-aside-block {
  width: 320rem;
  @include respond-to(table) {
    width: 100%; }
  &__register-wrapper {
    background-color: #EAF2F9;
    padding: 20rem; }
  //&__event-title
  //  font-size: $font-size-large
  //  font-weight: bold
  //  color: $bg-header-medium
  //  margin-bottom: 20rem
  //  text-transform: uppercase
  //&__input-label
  //  font-size: $font-size-increased
  //  margin-bottom: 10rem
  //&__input
  //  width: 100%
  //  height: 35rem
  //  background: #FBFBFB
  //  border: 1px solid #34A2C5
  //  box-sizing: border-box
  //  border-radius: 3px
  //  padding-left: 15rem
  //  font-size: $font-size-increased
  //  margin-bottom: 25rem
  //  outline: none
  //&__input::placeholder
  //  color: #D0C9D6
  //&__btn-wrapper
  //  display: flex
  //  justify-content: space-between
  //  margin-bottom: 20rem
  &__btn {
    width: 125rem;
    @include respond-to(table) {
      width: 45%; } }
  &__btn-register {
    width: 100%;
    background-color: #1DBA78;
    margin-bottom: 20rem; }
  &__btn-register:hover {
    background-color: #1cba69; } }

.events-main-block {
  &__main-event-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    margin-bottom: 20rem;
    text-transform: uppercase; }
  &__small-text {
    font-size: $font-size;
    color: #8C8C8C;
    display: inline; }
  &__small-text-span {
    margin-left: 5rem;
    padding-right: 15rem;
    border-right: 2px solid #8C8C8C; }
  &__small-text-date {
    margin-left: 5rem;
    padding-right: 15rem; }
  &__email {
    margin-left: 15rem;
    text-decoration: none;
    color: $bg-header-dark; }
  &__header-wrapper {
    margin-bottom: 55rem;
    @include respond-to(table) {
      margin: 30rem 20rem 20rem; } }
  &__register-btn {
    margin-left: 30rem;
    width: 250rem; }
  &__event-title {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: 7rem;
    @include respond-to(table) {
      margin: 30rem 20rem 5rem; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 10rem 20rem 20rem; } }
  &__mobile-text {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 10rem 20rem 20rem; } }
  &__block-text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify; }
  &__block-text-wrapper {
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 30rem 20rem 20rem; } }
  &__file-icon {
    font-size: $font-size-large;
    color: $bg-header-medium;
    margin-right: 15rem; }
  &__link {
    font-size: $font-size-large; }
  &__file-link {
    margin-bottom: 100rem;
    @include respond-to(table) {
      margin: 30rem 20rem 20rem; } } }

.user-events-main-title {
  font-size: $font-size-h3;
  font-weight: bold;
  text-transform: uppercase;
  color: $bg-header-medium;
  margin-bottom: 40rem; }

