.logo-desktop {
  width: 290rem;
  height: 107rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $bg-logo-gradient;
  border-radius: 8rem;
  padding: 15rem;
  color: $color-font-menu;
  cursor: pointer;
  @include respond-to(table) {
    width: 188rem;
    height: 54rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background: $bg-header-medium;
    margin-left: 10rem;
    margin-top: 8rem; }
  &__img-wrapper {
    width: 91rem;
    height: 100%;
    margin-right: 28rem;
    background-size: cover;
    @include respond-to(table) {
      width: 100rem;
      height: 100%;
      margin-right: 14rem;
      background-size: cover; } }
  &__description {
    width: 200rem;
    text-align: center;
    text-transform: uppercase;
    & h2, & h4 {
      font-weight: bold;
      margin: 0;
      padding: 0; }
    & h2 {
      font-size: 40rem;
      @include respond-to(table) {
        font-size: 20rem; } }
    & h4 {
      font-size: 18rem;
      @include respond-to(table) {
        font-size: 9rem; } } } }
