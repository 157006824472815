.main {
  margin: 0;
  padding: 0;
  width: 100%;
  & .categories-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &__item {
      width: 24%;
      height: 215rem;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1%;
      padding: 23rem 15rem 15rem;
      background: $bg-rubric-hover-gradient;
      border-radius: 10rem;
      opacity: .8;
      animation: btn-blur 0.15s ease-in-out forwards;
      cursor: pointer;
      user-select: none;
      @include respond-to(table) {
        width: 48%;
        margin-bottom: 15rem; }
      @include respond-to(phone) {
        width: 99%; }
      &:hover {
        animation: btn-hover 0.15s ease-in-out forwards; } }
    &__icon-wrapper {
      width: 100rem;
      height: 100rem;
      & > img {
        width: auto;
        height: 100%; } }
    &__title {
      text-align: center;
      font-size: $font-size-medium;
      font-weight: 500;
      color: $color-font-menu;
      text-transform: uppercase; }
    &__main-preloader-wrapper {
      width: 100%;
      margin: 20rem auto; } }

  & .news-block {
    margin: 50px 0;
    &:hover {
      cursor: pointer; }
    &__head-line {
      font-size: $font-size-h3;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      padding: 5rem;
      border-bottom: 3px solid $border-headline-color; }
    &__list {
      padding: 0 25rem;
      margin-bottom: 30rem;
      @include respond-to(table) {
        padding: 0; } }
    &__item {
      clear: both;
      overflow: hidden;
      padding: 30rem 0;
      border-bottom: 2px solid $border-color;
      display: flex;
      @include respond-to(table) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%; } }
    &__img-wrapper {
      min-width: 28%;
      height: 160rem;
      //float: left
      display: inline-block;
      text-align: center;
      margin-right: 20px;
      overflow: hidden;
      @include respond-to(table) {
        width: 100%;
        height: auto; }
      & > img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain; }
      &__news-content {
        width: 70%; } }
    &__date {
      color: $color-font-extra-light;
      text-transform: uppercase; }
    &__title {
      font-size: $font-size-medium;
      font-weight: bold;
      color: $color-font;
      line-height: 1.4;
      text-transform: uppercase;
      text-decoration: underline;
      padding: 15rem 0;
      cursor: pointer;
      &:hover {
        color: $bg-header-medium; } }
    &__description {
      font-size: $font-size-increased;
      line-height: 24rem;
      color: $color-font-light;
      text-align: justify;
      height: 70rem;
      overflow-y: hidden; } }

  & .survey-block {
    margin: 50px 0;
    &__head-line {
      font-size: $font-size-h3;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      padding: 5rem; }
    &__inner {
      padding: 25rem;
      background: $bg-extra-light;
      border-radius: 5rem; }
    &__title {
      font-size: $font-size-medium;
      font-weight: bold;
      color: $color-font;
      line-height: 1.4;
      text-transform: uppercase;
      padding-bottom: 15rem; }
    &__answer-box {
      padding: 0 10rem 15rem; }
    &__answer-item {
      margin-bottom: 10rem;
      font-size: $font-size-increased;
      color: $color-font-light;
      cursor: pointer;
      user-select: none;
      & > .fa {
        width: 30rem;
        //margin-right: 10rem
        color: $color-font-extra-light;
        font-size: $font-size-large; }
      &:hover > .fa {
        color: $bg-header-medium; }
      & > .fa-check-circle-o {
        color: $bg-header-medium; }
      & > .fa-check-square-o {
        color: $bg-header-medium; } } } }


.news-search-block {
  height: 180rem !important; }

.search-buttons-wrapper {
  width: 33%;
  display: flex;
  justify-content: space-around; }

.search-button {
  width: 150rem; }
