.additional-materials-block {
  &__main-training-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__search-block {
    width: 100%;
    height: 240rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 75rem;
    @include respond-to(table) {
      height: auto; }
    &--search-title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      margin-bottom: 20rem; } }
  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      flex-direction: column;
      align-items: center; } }
  &__input-label, .form-label {
    margin-bottom: 10rem; }
  &__form-group, .form-group {
    display: flex;
    flex-direction: column;
    width: 30%;
    @include respond-to(table) {
      width: 100%; } }
  &__search-btn {
    width: 18%;
    @include respond-to(table) {
      width: 100%; } }
  &__first-row {
    margin-bottom: 25rem; } }

.one-additional-material {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 55rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__description {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify; }
  &__info-img {
    width: 100%; }
  &__full-title {
    font-size: $font-size-h3;
    font-weight: bold;
    margin-bottom: 20rem; }
  &__col-wrapper {
    padding: 0;
    margin-bottom: 5rem; }
  &__description-col-wrapper {
    padding: 0;
    margin-bottom: 10rem; }
  &__author-title {
    font-size: $font-size-medium;
    color: $color-font-light; }
  &__author-name {
    font-size: $font-size-medium;
    color: $color-font; }
  &__full-name-title {
    font-size: $font-size-medium;
    color: $color-font-light; }
  &__full-name {
    font-size: $font-size-medium;
    color: $color-font; }
  &__email-title {
    font-size: $font-size-medium;
    color: $color-font-light; }
  &__email {
    font-size: $font-size-medium;
    color: $color-font; }
  &__link {
    font-size: $font-size-large; }
  &__file-icon {
    margin-right: 10rem; } }

.full-name {
  font-size: $font-size-large;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-font; }

.additional-info {
  margin-bottom: 50rem; }

.annotation {
  margin-bottom: 30rem; }
