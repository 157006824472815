.investment-projects-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 25rem;
    cursor: pointer; }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 50rem;
    height: 159rem;
    overflow-y: hidden; } }
.one-investment-project-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 60rem; }
  &__title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $color-font;
    margin-bottom: 20rem; }
  &__scroll-group {
    margin-bottom: 40rem;
    &--row {
      margin-bottom: 20rem; }
    &--item-title {
      font-size: $font-size-medium;
      font-weight: bold;
      color: $color-font-light; }
    &--item-value {
      font-size: $font-size-medium;
      color: $color-font-light; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    margin-bottom: 38rem; } }
