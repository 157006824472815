.faq-block {
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    line-height: 33rem;
    margin-bottom: 30rem;
    text-transform: uppercase;
    @include respond-to(table) {
      margin: 54rem 24rem 25rem; } }
  &__question-title {
    font-size: $font-size-large;
    font-weight: bold;
    margin-bottom: 10rem;
    @include respond-to(table) {
      margin: 0 24rem 10rem; } }
  &__question-answer {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 25rem;
    text-align: justify;
    @include respond-to(table) {
      margin: 0 24rem 25rem; } }
  &__mobile-question-answer {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 25rem;
    @include respond-to(table) {
      margin: 0 24rem 25rem; } }

  &__question-answer > a {
    text-decoration: underline; } }
