.best-practice-block {
  &__wrapper {
    margin-bottom: 50rem; }
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    @include respond-to(table) {
      display: flex;
      flex-direction: column;
      margin: 54rem 24rem 25rem; } }
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    line-height: 33rem;
    margin-bottom: 30rem;
    text-transform: uppercase;
    cursor: pointer; }
  &__date {
    padding: 0;
    margin: 0;
    font-size: $font-size;
    color: $color-font-extra-light;
    line-height: 1.5; }
  &__image {
    width: {}
    background-color: #c4c4c4;
    //position: absolute
    width: 300rem;
    height: 100%;
    //left: 163px
    //top: 492.43px
    border-radius: 3px;
    float: left;
    margin-right: 20rem;
    @include respond-to(table) {
      display: block;
      width: 100%;
      //height: 320rem
      border-radius: 0;
      margin-bottom: 45rem; } }
  &__text-block {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 25rem;
    text-align: justify;
    height: 160rem;
    overflow-y: hidden;
    @include respond-to(table) {
      margin: 0 24rem; } }
  &__single-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 75rem; }
  &__search-block {
    min-width: 100%;
    height: 255rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 75rem;
    @include respond-to(table) {
      height: auto; }
    &--search-title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      margin-bottom: 20rem; }
    &--text-input {
      width: 39%;
      @include respond-to(table) {
        width: 90%; } }
    &--btn-wrapper {
      display: flex;
      width: 18%;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 14rem;
      @include respond-to(table) {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 14rem; } } }

  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      flex-direction: column;
      align-items: center; } }
  &__input-label, .form-label {
    margin-bottom: 10rem; }
  &__input {
    width: 100%; }
  &__form-group, .form-group {
    display: flex;
    flex-direction: column;
    width: 18%;
    @include respond-to(table) {
      width: 100%; } }
  &_form-group, .practice-list {
    width: 23%;
    @include respond-to(table) {
      width: 100%; } }
  &__search-btn {
    width: 18%;
    height: 35rem;
    vertical-align: bottom;
    @include respond-to(table) {
      width: 100%; } }
  &__first-row {
    margin-bottom: 10rem; }
  &__second-row {
    display: flex;
    justify-content: space-between; } }

.best-practice-example-block {
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    line-height: 33rem;
    margin-bottom: 25rem;
    text-transform: uppercase;
    cursor: pointer;
    @include respond-to(table) {
      margin: 0 20rem 20rem; } }
  &__subtitle {
    font-size: $font-size-large;
    font-weight: bold;
    color: $color-font;
    margin-bottom: 25rem;
    @include respond-to(table) {
      margin: 7rem 20rem; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 40rem;
    text-align: justify;
    line-height: 28rem;
    @include respond-to(table) {
      margin: 40rem 20rem; } }
  &__mobile-text {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 40rem;
    line-height: 28rem;
    @include respond-to(table) {
      margin: 40rem 20rem; } }
  &__video {
    margin: 60rem 0; }
  &__additional-block-wrapper {
    margin-bottom: 40rem;
    @include respond-to(table) {
      margin: 0 20rem; } }
  &__additional-wrapper {
    margin-bottom: 23rem; }
  &__additional-title {
    font-size: $font-size-medium;
    font-weight: bolder;
    color: $color-font-light; }
  &__additional-content {
    font-size: $font-size-medium;
    font-weight: normal;
    text-align: justify;
    color: $color-font-light; }
  &__table {
    margin-bottom: 50rem; }
  &__comments-block {
    margin-bottom: 50rem;
    @include respond-to(table) {
      margin: 0 20rem; }
    &--title {
      font-size: $font-size-h4;
      font-weight: bold;
      color: $color-font;
      padding-bottom: 10rem;
      border-bottom: 2px solid #c4c4c4;
      position: relative; }
    &--count {
      color: $bg-header-medium; }
    &--chat {
      position: absolute;
      bottom: 3rem;
      right: 0;
      width: 30rem; }

    &--single-comment-wrapper {
      display: flex;
      justify-content: flex-start;
      position: relative;
      margin-bottom: -30rem; }
    &--inner-comment-wrapper {
      margin: 30rem 15rem; }
    &--avatar {
      width: 40rem;
      height: 40rem;
      display: inline;
      position: relative;
      top: 25rem; }
    &--comment-author {
      font-size: $font-size-medium;
      font-weight: 500;
      color: $bg-header-medium; }
    &--comment-text {
      text-align: justify; }
    &--comments-wrapper {
      margin-bottom: 30rem; }
    &--underline {
      height: 2px;
      width: 70%;
      background-color: #c4c4c4;
      margin-bottom: 50rem;
      @include respond-to(table) {
        width: 100%; } }
    &--new-comment {
      display: flex;
      justify-content: space-between; }
    &--avatar-new-comment {
      width: 40rem;
      margin-right: 15rem; }
    &--input {
      height: 43rem;
      border-radius: 5rem;
      border: 1px solid #c4c4c4;
      padding-left: 25rem;
      margin-right: 15rem; }
    &--input::placeholder {
      font-size: $font-size-medium;
      color: #8c8c8c;
      line-height: 30rem; }
    &--send {
      width: 30rem;
      cursor: pointer; } } }
.chat-icon {
  width: 36rem;
  position: absolute;
  right: 0;
  bottom: 3rem; }
.fill-best-practice-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin-top: 20rem; } }
  &__textarea-row {
    margin-bottom: 15rem; }
  &__textarea-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin-top: -15rem; }
  &__form-group {
    margin-bottom: 30rem;
    width: 100%; }
  &__label {
    font-size: $font-size-medium;
    color: $color-font;
    margin-bottom: 10rem;
    margin-right: 25rem;
    display: flex;
    justify-content: space-between; }
  &__input-wrapper {
    display: flex;
    position: relative; }
  &__input {
    margin-right: 25rem;
    border: 1px solid $bg-header-light; }
  &__input::placeholder {
    font-size: $font-size-increased;
    color: #c4c4c4; }
  &__textarea.form-control {
    margin-right: 25rem;
    height: 145rem;
    border: 1px solid $bg-header-light; }
  &__textarea.form-control::placeholder {
    font-size: $font-size-increased;
    color: #c4c4c4; }
  &__question-sign {
    cursor: pointer;
    position: absolute;
    top: 5rem;
    right: 0; }
  &__datepicker-wrapper {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &__datepicker {
    border: 1px solid $bg-header-light; }
  &__forms-block {
    margin-bottom: 60rem; }
  &__photo-block {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    margin: 50rem -1.5%;
    &--input-file {
      display: none; }
    &--pic-upload {
      width: 22%;
      height: 185rem;
      background-color: #E5F0F7;
      margin: 0 1.5% 30rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      cursor: pointer;
      @include respond-to(table) {
        width: 100%; } }
    &--camera {
      font-size: 120rem;
      color: rgba(#8c8c8c, 0.5); }
    &--image-wrapper {
      width: 22%;
      margin: 0 1.5% 30rem;
      position: relative;
      @include respond-to(table) {
        width: 100%;
        height: auto;
 } }        //margin: 0 10rem


    &--image {
      width: 100%;
      height: 185rem;
      background-color: #E5F0F7;
      border-radius: 3px;
      border: 0;
      margin: 0 1.5% 30rem;
      z-index: 100;
      @include respond-to(table) {
        margin: 0;
        height: auto; } }
    &--image-close-wrapper {
      width: 30rem;
      height: 30rem;
      position: absolute;
      left: 10rem;
      top: 5rem;
      background: rgba(#333333, 0.7);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center; }
    &--image-close {
      font-size: 18rem;
      cursor: pointer;
      color: #d6d6d6; } }
  &__table-block {
    margin-bottom: 75rem;
    &--plus-icon {
      margin-right: 10rem; }
    &--btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20rem; }
    &--btn {
      background-color: $bg-header-medium;
      width: 18%;
      @include respond-to(table) {
        width: 100%;
        height: 40rem; } } }
  &__save-btn {
    width: 18%;
    @include respond-to(table) {
      width: 100%;
      height: 40rem; } } }

.mandatory {
  color: red;
  font-size: 20rem; }
.mandatory-description {
  margin-left: 30rem;
  margin-right: 30rem; }
.react-datepicker-wrapper {
  width: 45%; }

@include respond-to(table) {
  .row {
    margin-left: 0;
    margin-right: 0; } }

.best-practice-list-container {
  min-width: 100%; }
