.journals-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 25rem;
  @include respond-to(table) {
    margin: 0 0 25rem 0; }
  &__title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem; }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: $color-font-extra-light;
    &-row {
      margin-bottom: 10rem; } }
  &__description {
    margin-top: 2rem;
    font-size: $font-size-medium;
    color: $color-font;
    text-align: justify; }
  &__mobile-description {
    margin-top: 2rem;
    font-size: $font-size-medium;
    color: $color-font; } }
