.authorization {
  &__wrapper {
    width: 30%;
    margin: 0 auto;
    @include respond-to(table) {
      width: 100%; } }
  &__title {
    font-size: $font-size-h3;
    text-transform: uppercase;
    font-weight: bold;
    color: $bg-header-medium;
    margin-bottom: 15rem;
    @include respond-to(table) {
      margin-top: 25rem; } }
  &__label {
    margin-bottom: 8rem;
    font-size: $font-size-medium; }
  &__input {
    border: 1px solid $bg-header-light;
    background: #FBFBFB;
    margin-bottom: 15rem; }
  &__input-password {
    border: 1px solid $bg-header-light;
    background: #FBFBFB;
    margin-bottom: 0; }
  &__btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 8rem 0 15rem; }
  &__btn {
    width: 45%; }
  &__btn-icon {
    width: 15rem;
    margin-right: 5rem; }
  &__file-input {
    display: none; }
  &__avatar-wrapper {
    position: relative;
    height: 250rem;
    border: 1px solid #ccc; }
  &__avatar-wrapper-pic {
    position: relative;
    height: 100%;
    border: 1px solid #ccc; }
  &__avatar {
    color: #1c1c1c;
    position: relative;
    top: calc(50% - 9rem); }
  &__avatar-pic {
    color: $color-font-menu;
    position: relative;
    top: calc(50% - 9rem); }
  &__avatar-preview {
    height: 250rem;
    width: 100%;
    border: transparent; }
  &__avatar-preview-pic {
    height: 100%;
    width: 100%;
    border: transparent; }
  &__button-wrapper {
    position: absolute;
    bottom: -1px;
    width: 100%; }
  &__file-upload {
    //border: 1px solid #ccc
    display: flex;
    justify-content: space-around;
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    height: 50rem;
    //border-radius: 3px
    background: rgba(255, 255, 255, 0.7);
    //margin-bottom: 15rem
    border-top: 1px solid #ccc;
    //border-left: 1px solid #ccc
    //border-right: 1px solid #ccc
    vertical-align: middle;
    position: relative;
    font-size: 18rem; }
  &__avatar-size {
    font-size: $font-size-increased;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15rem;
    color: #ff7b16; }

  &__checkbox {
    width: 18rem;
    margin-top: 2rem;
    margin-right: 7rem; }

  &__checkbox-privacy {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15rem; }

  //&__term
 }  //  text-align: center

.avatar-label {
  display: flex;
  justify-content: space-between; }
.avatar-trash {
  font-size: 20rem;
  color: #ccc;
  line-height: 30rem; }

.login-modal {
  width: 40%;
  height: 30%;
  margin: auto;
  background: #c4c4c4;
  &__title {
    font-size: $font-size-h3;
    text-transform: uppercase;
    font-weight: bold;
    color: $bg-header-medium;
    padding: 15rem; } }
.password-wrapper {
  margin-bottom: 0; }

.change-password {
  display: block;
  font-size: $font-size-medium;
  color: $bg-header-medium;
  margin-top: 20rem;
  margin-bottom: 15rem;
  cursor: pointer; }

.password {
  margin-bottom: 20rem; }

.PhoneInputInput {
  outline: none;
  height: 35rem;
  border: 1px solid #34a2c5;
  border-radius: 3rem;
  padding-left: 10rem;
  background: #FBFBFB; }

.PhoneInputInput:focus {
  background: #ffffff; }

.PhoneInputCountryIcon {
  width: 40rem !important;
  height: auto !important; }

.PhoneInputCountryIcon--border {
  background: none !important;
  box-shadow: none !important;
  border: 1px solid #ccc !important; }
