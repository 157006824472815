@keyframes btn-hover {
  from {
    opacity: .8; }
  to {
    opacity: 1; } }

@keyframes btn-blur {
  from {
    opacity: 1; }
  to {
    opacity: .8; } }

@keyframes nav--item-open {
  from {
    height: 0; }
  to {
    height: 195rem; } }

@keyframes nav--item-close {
  from {
    height: 195rem; }
  to {
    height: 0; } }

@keyframes profile-dropdown-open {
  from {
    height: 0; }
  to {
    height: 180rem; } }

@keyframes profile-dropdown-close {
  from {
    height: 180rem; }
  to {
    height: 0; } }

@keyframes mobile-menu-open {
  from {
    height: 0; }
  to {
    height: 100%; } }

@keyframes mobile-menu-close {
  from {
    height: 100%; }
  to {
    height: 0; } }

@keyframes mobile-sub-menu-open {
  from {
    height: 0; }
  to {
    height: 240rem; } }

@keyframes mobile-sub-menu-close {
  from {
    height: 240rem; }
  to {
    height: 0; } }

@keyframes mobile-profile-menu-open {
  from {
    height: 0; }
  to {
    height: 190rem; } }

@keyframes mobile-profile-menu-close {
  from {
    height: 190rem; }
  to {
    height: 0; } }

@keyframes login-modal-open {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes picture-viewer-open {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

//@keyframes login-modal-close
//  from
//    opacity: 1
//  to
//    opacity: 0
