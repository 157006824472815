.mobile-subcategory-menu-wrapper {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: block; }
.mobile-subcategory-menu-card > .card-header {
  height: 58rem;
  background: $bg-header-light;
  position: relative; }
.mobile-subcategory-menu-card-title {
  font-size: $font-size-large;
  font-weight: bold;
  color: $color-font-menu;
  text-transform: uppercase;
  position: relative;
  top: 12rem;
  left: 20rem; }
