.news-block {
  margin: 50px 0;
  &:hover {
    cursor: pointer; }
  &__head-line {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    padding: 5rem;
    border-bottom: 3px solid $border-headline-color; }
  &__list {
    //padding: 0 25rem
    margin-bottom: 30rem;
    @include respond-to(table) {
      padding: 0; } }
  &__item {
    clear: both;
    overflow: hidden;
    padding: 30rem 0;
    border-bottom: 2px solid $border-color;
    display: flex;
    @include respond-to(table) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%; } }
  &__img-wrapper {
    min-width: 28%;
    height: 160rem;
    //float: left
    display: inline-block;
    text-align: center;
    margin-right: 20px;
    overflow: hidden;
    @include respond-to(table) {
      width: 100%;
      height: auto; }
    & > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain; }
    &__news-content {
      width: 70%; } }
  &__date {
    color: $color-font-extra-light;
    text-transform: uppercase; }
  &__title {
    font-size: $font-size-medium;
    font-weight: bold;
    color: $color-font;
    line-height: 1.4;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 15rem 0;
    cursor: pointer;
    &:hover {
      color: $bg-header-medium; } }
  &__description {
    font-size: $font-size-increased;
    line-height: 24rem;
    color: $color-font-light;
    text-align: justify;
    height: 70rem;
    overflow-y: hidden; } }

.search-page-search-block {
  min-width: 100%;
  height: 255rem;
  background-color: $bg-extra-light;
  padding: 22rem 20rem;
  margin-bottom: 75rem;
  &__form-block {
    display: flex;
    justify-content: space-between; }
  &__btn-wrapper {
    width: 30%;
    display: flex;
 } }    //justify-content: space-between

.search-block-btn {
  width: 140rem;
  height: 40rem; }

