.search-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30rem;
  background-color: $bg-extra-light;
  padding: 30rem 20rem;
  border-radius: 3rem;
  &__title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $bg-header-medium;
    margin-bottom: 15rem;
    text-transform: uppercase; }
  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      display: flex;
      flex-direction: column; } }
  &__form-group {
    display: flex;
    flex-direction: column;
    width: 30%;
    @include respond-to(table) {
      width: 100%;
      margin-bottom: 20rem; } }
  &__label {
    font-size: $font-size-increased;
    margin-bottom: 5rem; }
  &__label-search {
    display: block; }
  &__search-wrapper {
    @include respond-to(table) {
      margin-top: -15rem; } }
  &__input {
 }    //border: 1px solid $bg-header-light
  &__search-input {
    @include respond-to(table) {
      margin-bottom: 25rem; } }
  &__btn {
    width: 18%;
    padding-left: 40rem;
    padding-right: 40rem;
    margin-left: 20rem;
    @include respond-to(table) {
      margin-left: 0; } }

  &__datepicker-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .react-datepicker-wrapper {
      margin-left: 15rem;
      &:first-child {
        margin-left: 0; } } } }


.table-block {
  &__table {
    min-width: 100%;
    border-collapse: collapse;
    border-radius: 15rem 15rem 0 0; }
  &__table, tr, th, td {
    border: 1px solid $bg-header-medium; }
  &__table-header {
    text-align: center;
    height: 50rem;
    color: $color-font-menu;
    background-color: $bg-header-light; }
  &__table-header > th {
    font-size: $font-size-increased; }
  &__table-body > tr {
    font-size: $font-size-increased; }
  &__table-body > tr > td {
    padding: 10rem 15rem; }
  &__table-body > tr > td:last-child {
    padding-left: 45rem;
    position: relative; }
  &__file-icon {
    color: $bg-header-medium;
    position: absolute;
    top: 19rem;
    left: 25rem;
    width: 12rem;
    height: 16rem;
    @include respond-to(table) {
      position: relative;
      top: 0;
      left: 0;
      margin-right: 10rem; } }
  &__mobile-card {
    &:nth-of-type(1) {
      border-top: 2px solid $bg-header-medium; }
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $bg-header-medium; }
  &__mobile-card-body {
    padding: 18rem 40rem; }
  &__mobile-card-body > div > .col-1 {
    padding-left: 0;
    padding-right: 0; }
  &__mobile-card-body > div > .col-4 {
    padding-left: 0;
    padding-right: 0; }
  &__mobile-card-body > div > .col-5 {
    padding-left: 0;
    padding-right: 0; }
  &__mobile-card-body > div > .col-6 {
    padding-left: 0;
    padding-right: 0; }
  &__mobile-card-body > div > .col-7 {
    padding-left: 0;
    padding-right: 0; }
  &__mobile-card-title {
    font-size: $font-size;
    font-weight: 500;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 20rem;
    padding-left: 0;
    line-height: 26rem; }
  &__mobile-card-description {
    font-size: $font-size-increased; }
  &__mobile-card-row {
    margin-bottom: 15rem; }
  &__mobile-card-description-redirect {
    cursor: pointer;
    color: $bg-header-light; } }

.title-input {
  width: 60%;


  //&__select
  //  height: 35rem
  //  width: 215rem
  //  font-size: 14rem
  //  border: 1px solid $bg-header-light
  //  border-radius: 3px
  //  display: inline
  //  margin-right: 25rem

  //&__date-picker-wrapper
  ////flex-direction: row


  //.datePickerWrapper .datePicker
  //  display: flex
  //  border: 1px solid $bg-header-light
  //  border-radius: 3px
  //  padding: 6rem 5rem 6rem 30rem
  //  color: #5c6873
  //
  //&__datePickerWrapper
  //  position: relative
  //
  //&__date-picker-from
  //  position: absolute
  //  font-size: 14rem
  //  font-weight: bold
  //  left: 11rem
  //  bottom: 8rem
  //  z-index: 1000
  //&__datePicker
  //  display: flex
  //  width: 180rem
  //  height: 35rem
  //  border: 1px solid $bg-header-light
  //  border-radius: 3px
  //  padding: 6rem 5rem 6rem 35rem
  //  color: #5c6873
  //&__datepicker
  //  position: absolute
  //  top: 0
  //  left: 0
  //&__datePicker-to
  //  margin-top: 28rem
  //&__number
  //  padding-left: 7rem
  //&__search
  //  height: 35rem
  //  border: 1px solid $bg-header-light
  //  border-radius: 3px
  //  width: 75%
  //  margin-right: 60rem
  //  font-size: 14rem
  //  padding: 7rem
  //&__search-btn
 }  //  padding: 0 67rem
