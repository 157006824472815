.training-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -12rem;
  @include respond-to(table) {
    margin: 0; }
  &__single-training {
    width: 22%;
    margin: 12rem;
    @include respond-to(table) {
      width: 100%;
      margin: 0; } }
  &__image-wrapper {
    width: 100%;
    height: auto;
    border-radius: 3px;
    margin-bottom: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer; }
  &__image-wrapper:hover {
    background-color: #f6f6f6;
    border: 1px solid #f0f0f0;
    transform: scale(1.1); }
  &__image {
    border-radius: 3px;
    @include respond-to(table) {
      margin-top: 20rem;
      border-radius: 0;
      height: auto; } }
  &__training-title {
    font-size: $font-size-h4;
    color: $bg-header-light;
    cursor: pointer;
    margin-bottom: 5rem;
    @include respond-to(table) {
      margin: 0 20rem 30rem; } } }

.training-main-block {
  &__main-training-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    margin-bottom: 20rem;
    text-transform: uppercase;
    @include respond-to(table) {
      margin: 20rem 20rem 5rem; } }
  &__file-icon {
    font-size: $font-size-large;
    color: $bg-header-medium;
    margin-right: 15rem; }
  &__register-btn {
    width: 250rem;
    margin-bottom: 20rem;
    margin-left: 30rem; }
  &__file-link {
    margin-bottom: 32rem;
    @include respond-to(table) {
      margin: 20rem 0 0 20rem; } }
  &__link {
    font-size: $font-size-large;
    text-decoration: underline; }
  &__training-title {
    font-size: $font-size-large;
    color: $color-font;
    margin-bottom: 7rem;
    @include respond-to(table) {
      margin: 20rem 20rem 5rem; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 20rem 20rem 25rem; } }
  &__mobile-text {
    font-size: $font-size-medium;
    color: $color-font-light;
    margin-bottom: 30rem;
    @include respond-to(table) {
      margin: 20rem 20rem 25rem; } }
  &__personal-area-text {
    margin-bottom: 60rem; }
  &__card-header {
    background-color: $bg-header-light;
    height: 45rem;
    @include respond-to(table) {
      height: 100%; } }
  &__training-date {
    font-size: $font-size-increased;
    color: #f3f3f3;
    line-height: 43rem;
    //margin-left: 25rem
    @include respond-to(table) {
      margin-left: 0;
      margin-bottom: 10rem; } }
  &__item-wrapper {
    display: flex;
    @include respond-to(table) {
      display: flex;
      flex-direction: column; } }
  &__image {
    width: 445rem;
    height: 260rem;
    //background-color: #C4C4C4
    margin: 40rem 20rem;
    @include respond-to(table) {
      width: 90%;
      margin: 15rem 20rem; } }
  &__pic {
    width: 100%;
    height: auto;
    margin-bottom: 30rem;
    @include respond-to(phone) {
      margin-bottom: 20rem; } }
  &__info-block {
    margin-top: 40rem;
    height: 100%;
    width: 70%;
    min-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(table) {
      margin: 0 20rem 10rem;
      height: 100%;
      width: 90%; }
 }    //align-content: space-between
  //&__info-block > span, div > span
  //  font-size: $font-size-medium
  //  color: $color-font-light
  &__info-block-row-wrapper, .row {
    @include respond-to(table) {
      margin-bottom: 10rem; } }
  &__info-wrapper {
    margin-bottom: 12rem; }
  &__info-block-item {
    display: block;
    margin-bottom: 13rem;
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    @include respond-to(table) {
      display: inline;
      text-align: justify; } }
  &__info-block-value {
    color: $color-font;
    @include respond-to(table) {
      line-height: 26rem; } }
  &__item-title {
    font-weight: bold;
    display: block; }
  &__contacts {
    margin: 0 10rem 0 0; }
  &__training-material-title {
    font-size: $font-size-large;
    font-weight: 500;
    color: $color-font-menu;
    padding-left: 20rem;
    line-height: 42rem;
    display: inline;
    @include respond-to(table) {
      line-height: 25rem;
      padding-left: 0;
      margin-top: 10rem;
      margin-bottom: 10rem; } }
  &__video {
    display: flex;
    justify-content: flex-start;
    //width: 70%
    //height: 40%
    margin: 30rem 0;
    @include respond-to(table) {
      width: 100%;
      margin: 20rem 0 40rem; } }
  &__nav-icon {
    color: $color-font-menu;
    float: right;
    line-height: 40rem;
    margin-right: 20rem;
    @include respond-to(table) {
      margin: auto 20rem; } }
  &__card-header-outer-wrapper {
    display: flex;
    justify-content: space-between;
    @include respond-to(table) {
      margin-left: 20rem; } }
  &__card-header-inner-wrapper {
    display: flex;
    justify-content: space-between;
    width: 95%; }
  &__accordion {
    margin-top: 40rem; } }

.training-materials-block {
  &__main-training-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__search-block {
    width: 100%;
    height: 240rem;
    background-color: $bg-extra-light;
    padding: 22rem 20rem;
    margin-bottom: 75rem;
    @include respond-to(table) {
      height: auto; }
    &--search-title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      margin-bottom: 20rem; } }
  &__form {
    width: 100%; }
  &__form-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include respond-to(table) {
      flex-direction: column;
      align-items: center; } }
  &__form-row-btn-wrapper {
    width: 100%; }
  &__input-label, .form-label {
    margin-bottom: 10rem; }
  &__form-group, .form-group {
    display: flex;
    flex-direction: column;
    width: 18%;
    @include respond-to(table) {
      width: 100%; } }
  &__search-btn {
    width: 18%;
    @include respond-to(table) {
      width: 100%; } }
  &__first-row {
    margin-bottom: 25rem; }

  &__single-material {
    margin-bottom: 25rem;
    display: flex;
    flex-direction: column;
    &--content-wrapper {
      @include respond-to(table) {
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
    &--title-and-date-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20rem;
      @include respond-to(table) {
        flex-direction: column; } }
    &--title {
      font-size: $font-size-h3;
      font-weight: bold;
      color: $bg-header-medium;
      text-transform: uppercase;
      cursor: pointer;
      width: 80%; }
    &--date {
      font-size: $font-size;
      color: $color-font-extra-light;
      padding-top: 5rem; }
    &--image-wrapper {
      width: 300rem;
      height: 100%;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      background-color: #c4c4c4;
      float: left;
      margin: 0 20rem 40rem 0;
      cursor: pointer;
      @include respond-to(phone) {
        position: relative;
        margin-bottom: 20rem;
        width: 100%; } }
    &--text {
      font-size: $font-size-medium;
      line-height: 25rem;
      text-align: justify;
      color: $color-font-light;
      height: 126rem;
      overflow-y: hidden; }
    &--news-text {
      font-size: $font-size-medium;
      line-height: 25rem;
      text-align: justify;
      color: $color-font-light; } } }
.one-training-material-block {
  &__info-block {
    margin-bottom: 75rem; }
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    color: $bg-header-medium;
    text-transform: uppercase;
    margin-bottom: 55rem;
    @include respond-to(table) {
      margin-top: 22rem; } }
  &__info-img {
    width: 445rem;
    height: 260rem;
    background-color: #c4c4c4;
    display: inline;
    @include respond-to(table) {
      width: 100%; } }
  &__info-wrapper {
    width: 100%;
    &--row {
      margin-bottom: 12rem;
      @include respond-to(table) {
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
    &--title {
      font-size: $font-size-medium;
      color: $color-font-light; }
    &--description, &--phone, &--email {
      font-size: $font-size-medium;
      color: $color-font; }
    &--phone {
      margin-right: 20rem; }
    &--about-block {
      width: 85%;
      margin-bottom: 10rem; }
    &--text-info {
      font-size: $font-size-medium;
      color: $color-font; } }
  &__video {
    width: 100%;
    margin-bottom: 75rem; } }

.long-title {
  width: 80%; }

.reset-settings {
  margin-right: 20rem; }

.training-wrapper {
  display: flex;
  justify-content: space-around;
  @include respond-to(table) {
    display: block; }
  @include respond-to(phone) {
    display: block; } }

.single-training-wrapper {
  @include respond-to(phone) {
 } }    //margin-top: 60rem
