.aside {
  &__wrapper {
    width: 320rem;
    max-width: 320rem;
    min-width: 320rem;
    padding: 0;
    margin-left: 20rem;
    @include respond-to(table) {
      width: 100%;
      max-width: 100%;
      min-width: 100%; }
    @include respond-to(table) {
      margin-left: 0; }
    @include respond-to(phone) {
      margin-left: 0; }
    & > div {
      margin-bottom: 15rem;
      &:last-child {
        margin-bottom: 0; } } }
  &__item {
    padding: 15rem;
    border-radius: 3rem;
    @include respond-to(table) {
      border-radius: 0; }
    & button {
      width: 100%; }
    & form {
      margin-top: 15rem; }
    & input {
      height: 34rem; }
    & input, & textarea {
      padding: 10rem;
      border: none;
      background: #fefefe;
      border-radius: 3rem;
      font-style: italic;
      &::placeholder {
        font-style: italic;
        color: #989898; } } }

  &-title {
    font-size: $font-size-large;
    font-weight: 500;
    color: $color-font; }

  &-subtitle {
    font-size: $font-size-increased;
    font-weight: 500;
    color: $color-font; }

  &-text {
    color: $color-font-light;
    padding: 15rem 0; }

  &__ad-block {
    height: 400rem;
    text-align: center;
    padding: 0;
    &--advertising {
      height: auto; }
    &--description > h2 {
      font-size: 28rem; }
    &--description > h3 {
      font-size: 24rem; }
    &--default {
      background: #f2ede3;
      font-size: $font-size-medium;
      font-weight: 500;
      line-height: 21rem;
      color: rgba(102, 150, 165, 0.67);
      text-transform: uppercase;
      padding-top: 100rem;
      & p {
        margin: 26rem 0; } } }

  &__feedback {
    padding-bottom: 30rem; }

  &__vote {
    padding-bottom: 30rem; }

  &__question-form {
    padding-bottom: 30rem; }

  &__donate {
    padding-bottom: 30rem; }

  &__nav {
    padding: 40rem 0;
    background-color: $bg-header-light;
    @include respond-to(table) {
      padding: 0 0 20rem 0; }

    &--card {
      border: none;
      //background-color: $bg-header-medium
      color: $color-font-menu;
      cursor: pointer; }
    &--card:hover {
      color: $color-font-menu-active; }
    .card-header {
      background-color: $bg-header-light;
      padding: 10rem 20rem; }
    .card-body {
      background-color: $bg-header-medium;
      color: $color-font-menu; }

    &--item {
      font-size: $font-size-large;
      padding-left: 15rem;
      padding-bottom: 5rem;
      margin-bottom: 5rem; }

    &--inside-item {
      padding: 5rem 0;
      font-size: 18rem;
      cursor: pointer;
      list-style: none; }
    &--inside-item:hover {
      color: $color-font-menu-active; }

    .active {
      color: $color-font-menu-active; } }
  &__nav-icon {
    margin-left: 10rem;
    position: relative;
    bottom: 2rem;
    font-size: $font-size;
    cursor: pointer; }
  &__select {
    margin-bottom: 15rem; } }
.progress-block {
  display: flex;
  align-items: center;
  padding: 15rem 0;
  position: relative;
  cursor: pointer;
  &__percent {
    position: absolute;
    right: 5rem;
    top: 0; }
  &__radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    border: 2px solid #178CB1;
    margin-right: 10rem;

    input {
      opacity: 0;
      pointer-events: none;
      position: absolute; }
    span {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      transition: .1s linear;
      cursor: pointer; }
    input:checked + span {
      background: #178CB1; } }
  &__progressbar {
    display: flex;
    background: #ffff;
    border-radius: 3rem;
    height: 10rem;
    overflow: hidden;
    width: 100%;
    >div:nth-of-type(1) {
      background: #178CB1; } } }

.nav--item-open {
  font-size: $font-size; }

.nav--item-close {
  padding-top: 0;
  padding-bottom: 0; }

.nav--item-open {
  animation: nav--item-open 0.15s ease-in-out forwards; }

.nav--item-close {
  animation: nav--item-close 0.15s ease-in-out forwards; }

.calendar-block {
  margin-bottom: 20rem;
  &__wrapper {
    padding: 20rem;
    background-color: #eaf2f9;
    @include respond-to(table) {
      padding: 0;
      margin-bottom: 20rem; } } }

.small-form-block {
  margin-bottom: 20rem;
  &__input-label {
    font-size: $font-size-increased;
    margin-bottom: 10rem;
    @include respond-to(table) {
      margin: 20rem 20rem 5rem; } }
  &__input {
    width: 100%;
    height: 35rem;
    background: #FBFBFB;
    border: 1px solid #34a2c5;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 15rem;
    font-size: $font-size-increased;
    margin-bottom: 25rem;
    outline: none;
    @include respond-to(table) {
      margin: 20rem 20rem 5rem;
      width: 90%; } }
  &__input::placeholder {
    color: #d0c9d6; }
  &__register-btn {
    width: 100%;
    background-color: #1dba78;
    font-weight: bold;
    @include respond-to(table) {
      margin: 20rem 20rem 25rem;
      width: 90%; } }
  &__register-btn:hover {
    background-color: #1cba69; }
  &__select-wrapper {
    margin-bottom: 10rem; } }

.large-form-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &__event-title {
    font-size: $font-size-large;
    font-weight: bold;
    color: $bg-header-medium;
    margin-bottom: 20rem;
    text-transform: uppercase;
    @include respond-to(table) {
      margin: 20rem 20rem 10rem; } }
  &__input-label {
    font-size: $font-size-increased;
    margin-bottom: 10rem;
    @include respond-to(table) {
      margin: 20rem 20rem 5rem; } }
  &__input {
    width: 100%;
    height: 35rem;
    background: #FBFBFB;
    border: 1px solid #34A2C5;
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 15rem;
    font-size: $font-size-increased;
    margin-bottom: 25rem;
    outline: none;
    @include respond-to(table) {
      margin: 20rem auto 5rem;
      width: 90%; } }
  &__input::placeholder {
    color: #d0c9d6; }
  &__btn-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin: 20rem 20rem 15rem;
      display: flex;
      justify-content: space-between; } } }

.vote-wrapper {
  margin-top: 15rem; }

.vote-wrapper__block > p {
  cursor: pointer; }
