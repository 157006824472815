.button-group {
  text-align: right;
  & > * {
    margin-left: 5px;
    &:first-child {
      margin-left: 0; } } }

.light-bg {
  background: $bg-light; }

.medium-bg {
  background: $bg-medium; }
.practice_wrapper {
  margin-bottom: 40rem;
  & > .carousel-control-prev,.carousel-control-next {
    & > .carousel-control-prev-icon,.carousel-control-next-icon {
      background-color: #000000;
      border-radius: 50%; } } }


.practice_slider {
  width: 100%;
  height: 500rem;
  @include respond-to(table) {
    margin-top: 20rem;
    width: 100%;
    height: auto; }
  & > img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain; } }

.best-practice-list-container {
  margin-left: 0; }

.picture-viewer {
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
  &__carousel {
    margin: auto;
    width: 400rem; }
  &__img {
    width: 400rem;
    height: auto; }
  &__close-icon {
    position: fixed;
    top: 40rem;
    right: 50rem;
    color: #ffffff;
    font-size: 25rem;
    font-weight: 100;
    cursor: pointer; } }

.picture-viewer-open {
  animation: picture-viewer-open 100ms ease-in-out forwards; }

.training-materials-block__single-material--news-text img {
  margin: 15rem; }

.privacy {
  max-width: 70%; }
