.typical-errors-block {
  &__main-title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    margin-bottom: 25rem;
    @include respond-to(table) {
      margin-top: 22rem;
      margin-left: 15rem; } }
  &__text {
    font-size: $font-size-medium;
    color: $color-font-light;
    text-align: justify;
    margin-bottom: 20rem;
    @include respond-to(table) {
      margin: 0 15rem 20rem 15rem; } } }
