.login-modal-wrapper {
  display: none;
  position: fixed;
  z-index: 100010;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: calc(100% + 40rem);
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0 -20rem !important; }

.login-modal-aside {
  background-color: #fefefe;
  margin: auto;
  //padding: 20px
  border: 1px solid #888;
  border-radius: 5rem;
  width: 25%;
  height: 450rem;
  position: relative;
  @include respond-to(table) {
    width: 60%; }
  @include respond-to(phone) {
    width: 90%; }
  &__message {
    color: #ff9b00;
    font-size: $font-size-medium;
    text-align: center; }
  &__link {
    color: $bg-header-medium;
    cursor: pointer; } }

.login-modal {
  background-color: #fefefe;
  margin: auto;
  //padding: 20px
  border: 1px solid #888;
  border-radius: 5rem;
  width: 25%;
  height: 405rem;
  position: relative;
  @include respond-to(table) {
    width: 60%; }
  @include respond-to(phone) {
    width: 90%; }
  &__label {
    margin-bottom: 8rem;
    font-size: $font-size-medium;
    color: $color-font; }
  &__input {
    border: 1px solid $bg-header-light;
    background: #FBFBFB;
    margin-bottom: 15rem; }
  &--footer {
    position: absolute;
    height: 60rem;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10rem; }
  &--footer > .row > .col-sm-4 {
    padding: 0; }
  &--header {
    position: absolute;
    height: 60rem;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-around; }
  &--error-message-wrapper {
    position: absolute;
    top: 65rem;
    width: 100%;
    display: flex;
    justify-content: space-around; }
  &--error-message {
    color: #db1800; }
  &--title {
    font-size: $font-size-h3;
    font-weight: bold;
    text-transform: uppercase;
    color: $bg-header-medium;
    padding: 30rem 0; }
  &--body {
    width: 90%;
    margin: 100rem auto; }
  &--btn-wrapper {
    display: flex;
    justify-content: space-between;
 }    //margin-right: 10rem
  &--btn {
    width: 40%;
    height: 40rem;
    border: none; }
  &__forgot-password {
    font-size: $font-size-medium;
    color: $bg-header-medium;
    cursor: pointer; } }
.active {
  display: block;
 }  //z-index: 100100
.close-btn {
  background: $bg-header-light; }
.close-btn:hover {
  background: $bg-header-medium; }

.login-modal-open {
  animation: login-modal-open 100ms ease-in-out forwards; }
//.login-modal-close
//  animation: login-modal-close 250ms ease-in-out forwards

.forgot-password-title {
  font-size: $font-size-h3;
  text-transform: uppercase;
  color: $bg-header-medium;
  font-weight: bold;
  margin-bottom: 50rem; }

.forgot-password-wrapper {
  width: 40%;
  margin: 0 auto; }

.forgot-password-input {
  width: 100%;
  margin-bottom: 50rem; }

.forgot-password-btn {
  width: 100%;
  margin-bottom: 100rem; }

.operator-code {
  font-size: 10rem;
  display: block; }

